<template>
  <div>
    <HighlightCarousel />
    <About />
  </div>
</template>

<script>
  import HighlightCarousel from '@/components/HighlightCarousel'
  import About from '@/components/About'
  export default {
    metaInfo: {
      meta: [
        {
          name: 'description',
          content: 'Tausende, bunte Dominosteine einfach über unseren Online-Shop bestellen. Dominokettenreaktionen Zuhause mit den besten Dominosteinen aufbauen. Bestellen Sie jetzt mit nur wenigen Klicks.'
        },
        {
          name: 'keywords',
          content: 'Dominosteine, Mieten, Kaufen, Farbig, Plastik, Schweiz'
        }
      ],
      title: 'Kettenreaktionen mit Dominosteinen'
    },
    components: {
      HighlightCarousel,
      About
    }
  }
</script>
