<template>
    <b-carousel :indicator-inside="false" :indicator="false" animated="slide" :interval="4000" :pause-info="false"
        :arrow="true" icon-pack="mdi" icon-prev="arrow-left" icon-next="arrow-right">
        <b-carousel-item v-for="(slide, i) in slides" :key="i">

            <section class="hero is-dark" v-bind:class="{'is-fullheight': isMobile(), 'is-large': !isMobile()}">
                <img :src="slide.img" class="is-transparent hero-background" />
                <div class="hero-body">
                    <div class="container">
                        <h1 class="title is-1"
                            :class="{'is-size-3-mobile' : slide.title.length > 14 && slide.title.indexOf('-') == -1}">
                            {{slide.title}}
                        </h1>
                        <p class="subtitle is-3" v-if="slide.sub">
                            {{slide.sub}}
                        </p>
                        <div style="margin-top: 20px" v-else></div>
                        <router-link v-if="slide.page" :to="slide.page.id" class="button is-medium is-white">
                            {{slide.page.display}}</router-link>
                    </div>
                </div>
            </section>
        </b-carousel-item>
    </b-carousel>
</template>
<script>
    export default {
        methods: {
            isMobile() {
                return this.$isMobile()
            },
            is_scroll_to_link(page) {
                return page.id.indexOf('#') > -1
            }
        },
        computed: {
            slides() {
                return [{
                        id: 1,
                        img: './img/headers/highlight_online_shop_domino.png',
                        title: 'Dominosteine zu Hause aufstapeln',
                        sub: 'Bestellen Sie tausende Dominosteine direkt über unseren Online-Shop!',
                        page: {
                            id: 'shop',
                            display: 'Jetzt zum Online-Shop'
                        }
                    },
                    {
                        id: 2,
                        img: './img/headers/highlight_mieten_domino.png',
                        title: 'Teamgeist und Konzentration von Schülern mit Dominobauen fördern',
                        sub: 'Exklusiver Vermietungsrabatt für Schulen',
                        page: {
                            id: 'mieten',
                            display: 'Jetzt informieren'
                        }
                    },
                    {
                        id: 3,
                        img: './img/headers/sde_at_school_domino_portal.png',
                        title: 'SDE @School',
                        sub: 'Das Online Portal gefüllt mit Tipps & Tricks zum Dominobauen ideal für Schulklassen.',
                        page: {
                            id: 'mieten',
                            display: 'Jetzt Zugang buchen'
                        }
                    },
                    {
                        id: 4,
                        img: './img/shop/kurs_ganztags.png',
                        title: 'Workshops rund ums Dominobauen',
                        sub: '',
                        page: {
                            id: 'workshop',
                            display: 'Jetzt informieren'
                        }
                    },
                    {
                        id: 5,
                        img: './img/headers/highlight_event_domino.png',
                        title: 'Das Dominospektakel für Ihr Event',
                        sub: '',
                        page: {
                            id: 'projekteundevents',
                            display: 'Jetzt informieren'
                        }
                    }
                ]
            }
        }
    }
</script>